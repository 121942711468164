@use '../vars'
.btn
  background: vars.$primary
  padding: 1rem 2rem
  width: 7rem
  margin: 2rem auto 0
  text-align: center
  transition: 200ms ease-in-out
  &:hover
    background: darken(vars.$primary, 15%)
    color: darken(vars.$white, 5%)

