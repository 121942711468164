// Variables

$font--open-sans: 'Open Sans', sans-serif

$weight--light: 300
$weight--regular: 400
$weight--bold: 700

$primary: #5CC5FF
$white: #FFFFFF
$grey: #595959
$black: #121212
