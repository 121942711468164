@use "../vars"
.data-box
  display: flex
  position: fixed
  top: 50%
  left: 50%
  width: 60%
  max-width: 50rem
  transform: translate(-50%, -50%)
  z-index: 12
  background: white
  flex-direction: column
  padding: 1rem 2.5rem 2.5rem
  opacity: 0
  margin: 0
  border: 10px solid vars.$primary
  border-radius: 20px
  //background: rgb(vars.$grey)
  background: rgba(vars.$black, .5)
  background: radial-gradient(circle, rgba(vars.$grey,1) 0%, rgba(vars.$black, .70) 100%)
  justify-content: center
  *
    color: vars.$white
  &--title
    color: vars.$primary
  &--location
    font: vars.$weight--regular 1rem vars.$font--open-sans
    em
      font: italic vars.$weight--light 1rem vars.$font--open-sans
