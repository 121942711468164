@use 'vars'
/*----------------------  2 [Base]  ----------------------
  /**
    * Base syntax should not contain any classes, id or use attribute selectors of any kind.
    * Resets or basic styles for elements belong here
    *
    * Example:
    *
    * header { ... }
    *
    *
*
  margin: 0
  padding: 0
  box-sizing: border-box
  &:before + &:after
    box-sizing: inherit
  + *
    margin: 1rem 0 0
html
  font-size: 100%
body
  background: #3d2e00
  overflow: hidden
  position: relative
  font: vars.$weight--regular 1.2rem/1.25 vars.$font--open-sans
h1
  margin-bottom: 0
  + p
    margin-top: 0
br
  height: 1rem
  content: ''
  display: block

nav
  display: flex
  position: fixed
  z-index: 10
  justify-content: center
  align-content: center
  flex-direction: column
  width: 40%
  margin: 0
  height: 100vh
  background: rgb(50,50,50)
  background: linear-gradient(90deg, rgba(10, 10, 10,1) 0%, rgba(50,50,50,0.05) 100%)
  top: 0
  padding: 1rem 2rem
  ul
    opacity: 0
    transform: translateY(-200%)
    transition: 300ms ease-in-out
  li
    transform: translateY(-100%)
    list-style: none
    transition: 200ms ease-in-out
    + li
      transition-delay: 200ms
      transition: 200ms ease-in-out
      transform: translateY(-100%)
  a
    text-decoration: none
    color: rgba(vars.$white, .80)
    transition: 200ms ease-in-out
    &:hover
      color: rgba(vars.$white, 1)
button
  margin: 0
  padding: 0
  border: none
  background: transparent
  color: black
/*----------------------  End Base  ----------------------
