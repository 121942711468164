/*----------------------  4 [Modules]  ----------------------
  /**
    *
    * Module code style naming conventions
    * 
    * .module--element__modifier { ... }
    * 
    * sass written as:
    * 
    * .module
    *    ...
    *   &--element
    *      ...
    *     &__modifier
    *        ...
    *  
    * html syntax:
    *
    * <div class="module module--element__modifier"> ... </div>
    *
    *
@import 'button'
@import 'data-box'
@import 'icons'
@import 'map'
@import 'menu'
@import 'nav'

/*----------------------  End Modules  ----------------------
