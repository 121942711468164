.icon
  width: 2.25rem
  position: absolute
  top: 0
  left: 0
  margin: 0
  cursor: pointer
  &--name
    position: absolute
    top: 2.75rem
    color: white
    opacity: 0
    margin: 0
    text-align: center
    left: -130%
    font-size: 1.2rem
    width: 8rem
  &--remote-bomb-sphere
    top: 2065px
    left: 1625px
    //.icon--name
  &--remote-bomb-cube
    top: 2085px
    left: 1625px
    //.icon--name
  &--stasis
    top: 2175px
    left: 1505px
    //.icon--name
  &--magnesis
    top: 1975px
    left: 1520px
    //.icon--name
  &--cryonis
    top: 2075px
    left: 1355px
    //.icon--name
  &--camera
    top: 2120px
    left: 2910px
    //.icon--name
  &--hylian-shield
    top: 1160px
    left: 1695px
    //.icon--name
  &--master-sword
    top: 825px
    left: 1915px
    //.icon--name
  &--spirit-orb
    top: 2080px
    left: 1540px
    //.icon--name
  &--korok-seeds
    top: 1905px
    left: 2405px
    //.icon--name
  &--mipha-grace
    top: 1550px
    left: 2675px
    //.icon--name
  &--revali-gale
    top: 935px
    left: 700px
    //.icon--name
  &--daruk-protection
    top: 725px
    left: 2515px
    //.icon--name
  &--urbosa-fury
    top: 2275px
    left: 1150px
    //.icon--name
  &--master-torch
    top: 1525px
    left: 2045px
    //.icon--name
